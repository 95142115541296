// @flow
import React, { useEffect } from 'react';
import Routes from './routes/Routes';

// setup fake backend
import { configureFakeBackend } from './helpers';

// setup axios interceptor
import { CustomAxiosResponseInterceptor } from './helpers/api/apiCore';

// import { AuthProvider } from './context/AuthContext';
// import { AuthProvider } from './utils/auth';

// redux
import { logoutUser } from './redux/actions';
import { authApiResponseSuccess } from './redux/actions';
import { AuthActionTypes } from './redux/auth/constants';
import { useDispatch } from 'react-redux';

// import { LogoutAllTabs } from './utils/auth';
// import { authChannel } from './utils/auth';
import { BroadcastChannel } from 'broadcast-channel';
// Themes

// For Saas import Saas.scss
import './assets/scss/Saas.scss';
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern dark demo import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
configureFakeBackend();


type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
    const dispatch = useDispatch();
    let today = new Date();

    useEffect(() => {
        let loggedIn = sessionStorage.getItem('hyper_user');

        if (!loggedIn) {
            localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', today.getSeconds().toString());
            localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
        }
    }, []);
    
    window.addEventListener('storage', (e) => {
        const credentials = JSON.parse(sessionStorage.getItem('hyper_user'));

        if (e.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
            localStorage.setItem('CREDENTIALS_SHARING', JSON.stringify(credentials));
            localStorage.removeItem('CREDENTIALS_SHARING');
        }

        if (e.key === 'CREDENTIALS_SHARING' && !credentials) {
            sessionStorage.setItem('hyper_user', e.newValue);
            dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, e.newValue));
        }

        if (e.key === 'REFRESH_SHARED_CREDENTIALS' && credentials) {
            sessionStorage.setItem('hyper_user', e.newValue);
            dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, e.newValue));
        }

        if(e.key === 'CREDENTIALS_FLUSH' && credentials) {
            dispatch(logoutUser());
        }
    });

    return <CustomAxiosResponseInterceptor>
            <Routes></Routes>
        </CustomAxiosResponseInterceptor>;
};

export default App;
