// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    forgotPasswordConfirm,
} from '../../helpers/';

import { APICore, setAuthorization } from '../../helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - userId and password
 */
function* login({ payload: { memberID, userPassword } }) {
    try {
        const response = yield call(loginApi, { memberID, userPassword });
        const user = response.data;
        // NOTE - You can change this according to response format from your api

        // console.log(user['testToken'].accessToken);
        // console.log(user['testToken'].authKey);

        api.setLoggedInUser(user);
        
        if (user['tokenInfo']) {
            setAuthorization(user['tokenInfo']);
        }

        let newSession = {...user};
        let tempPosition = user.position;
        let tempEmptyPosition = tempPosition.replace(" ", "");
        let tempPositionArr = tempEmptyPosition.split(",");
        newSession['position'] = tempPositionArr;

        localStorage.setItem('CREDENTIALS_SHARING', JSON.stringify(newSession));
        localStorage.removeItem('CREDENTIALS_SHARING');

        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, newSession));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout() {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { districtOfficeCode, jobTitle, position, userId, passwd, businessNumber
                            , businessAddress, companyName, mainPhone, faxNumber, tel, userName} }) {
    try {
        const response = yield call(signupApi, { districtOfficeCode, jobTitle, position, userId, passwd, businessNumber
            , businessAddress, companyName, mainPhone, faxNumber, tel, userName });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);

        // console.log('성공입니다.');
        // console.log(response);

        // if (response.status === 201) {
        //     yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
        // } else {
        //     yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, 'Failed to sign up for member'));
        // }

        let newSession = {...user};
        let tempPosition = user.position;
        let tempEmptyPosition = tempPosition.replace(" ", "");
        let tempPositionArr = tempEmptyPosition.split(",");
        newSession['position'] = tempPositionArr;

        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, newSession));
    } catch (error) {
        // console.log('에러입니다.');
        // console.log(error);
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { userId } }) {
    try {
        const response = yield call(forgotPasswordApi, { userId });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* forgotPasswordChange({ payload: { data } }) {
    try {
        const response = yield call(forgotPasswordConfirm, data);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchForgotPasswordChange),
    ]);
}

export default authSaga;
