import jwtDecode from 'jwt-decode';
import axios, { AxiosInstance } from 'axios';

import config from '../../config';

import urlData from '../../atons/json/url.json'

// import { logoutUser } from '../../redux/actions';
import { logoutUser } from '../../redux/auth/actions';
import { useDispatch } from 'react-redux';

import { authApiResponseSuccess } from '../../redux/auth/actions';
import { AuthActionTypes } from '../../redux/auth/constants';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;
axios.defaults.withCredentials = true;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else {
            switch (error.response.status) {
                case 300:
                    message = '로그인 아이디나 비밀번호를 다시 확인하세요.\n시스템 문의 : smart.atons@gmail.com';
                    break;
                case 301:
                    message = '메일 인증이 필요합니다.\n시스템 문의 : smart.atons@gmail.com';
                    break;
                case 302:
                    message = '관리자 승인중입니다.\n시스템 문의 : smart.atons@gmail.com';
                    break;
                case 303:
                    message = '사용할 수 없는 계정입니다.\n시스템 문의 : smart.atons@gmail.com';
                    break;
                case 304:
                    message = '로그인 아이디나 비밀번호를 다시 확인하세요.\n시스템 문의 : smart.atons@gmail.com';
                    break;
                case 401:
                    message = '사용자 정보가 잘못 되었습니다.\n시스템 문의 : smart.atons@gmail.com';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    let newUser = getUserFromSession();
    
    if (token) {
            axios.defaults.headers.common['accessToken'] = token.accessToken;
            customAxios.defaults.headers.common['accessToken'] = token.accessToken;
            // axios.defaults.headers.common['authKey'] = token.authKey;

            if (newUser.tokenInfo['accessToken'] !== token.accessToken) {
                let tempUser = newUser;
                tempUser['tokenInfo'] = token;
                sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(tempUser));
                // localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(tempUser));
                // if (newUser.tokenInfo['authKey'] !== token.authKey) {
                //     let tempUser = newUser;
                //     tempUser['tokenInfo'] = token;
                //     sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(tempUser));
                //     // localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(tempUser));
                // }
            }
    } else {
        delete axios.defaults.headers.common['accessToken'];
        // delete axios.defaults.headers.common['authKey'];
    }

    // if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    // else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    // const user = localStorage.getItem(AUTH_SESSION_KEY);
    
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        let response;

        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`);
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axios.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();
        if (!user || (user && !user.tokenInfo)) {
            return false;
        } else {
            return true;
        }

        // const decoded = jwtDecode(user.token);
        // const currentTime = Date.now() / 1000;
        
        // if (decoded.exp < currentTime) {
        //     console.warn('access token expired');
        //     return false;
        // } else {
        //     return true;
        // }
    };

    setLoggedInUser = (session) => {

        if (session) {
            let newSession = {...session};
            let tempPosition = session.position;
            let tempEmptyPosition = tempPosition.replace(" ", "");
            let tempPositionArr = tempEmptyPosition.split(",");
            newSession['position'] = tempPositionArr;
            
            sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(newSession));
            // localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        } else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
            // localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        // let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromSession();

// 기존
// if (user) {
//     const { token } = user;
//     if (token) {
//         setAuthorization(token);
//     }
// }

// 2022-09-07 수정
// if (user) {
//     const { tokenInfo } = user;
//     if (tokenInfo) {
//         setAuthorization(tokenInfo);
//     }
// }

const getRefreshAccessToken = async() => {
    try {
        return await customAxios.post(urlData['url'] + '/reToken');
    } catch (e) {
        console.log(e);
    }
};

const customAxios = axios.create({
    baseURL: config.API_URL,
    headers: { "Content-type": "application/json"},
    withCredentials: true
});

let isFlag = true;
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const CustomAxiosResponseInterceptor = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const interceptor = customAxios.interceptors.response.use(
            (response) => {
                if (response.data.response !== undefined && response.data.response !== null) {
                    return response;
                } else {
                    return response;
                }
            },
            (error) => {
                const { config } = error;
                const originalRequest = config;
        
                if (error.response.data.resultCode === 401) {
                    if (error.response.data.response.resultCode === '302') {
                        // token이 header에 없을 경우
                        if (isFlag) {
                            isFlag = false;
                            dispatch(logoutUser());
                            localStorage.setItem('CREDENTIALS_FLUSH', '');
                            localStorage.removeItem('CREDENTIALS_FLUSH');
                            window.location.href = '/';
                            alert(error.response.data.response.resultMessage);
                        }
                    } else if (error.response.data.response.resultCode === '303') {
                        // ip 불일치 (동시접속)
                        if (isFlag) {
                            isFlag = false;
                            dispatch(logoutUser());
                            localStorage.setItem('CREDENTIALS_FLUSH', '');
                            localStorage.removeItem('CREDENTIALS_FLUSH');
                            window.location.href = '/';
                            alert(error.response.data.response.resultMessage);
                        }
                    } else if (error.response.data.response.resultCode === '304' && !originalRequest._retry) {
                        // accesstoken 유효시간 지남
                        if (isRefreshing) {
                            return new Promise(function (resolve, reject) {
                                failedQueue.push({ resolve, reject});
                            }).then((token) => {
                                originalRequest.headers['accessToken'] = token;
                                return customAxios.request(originalRequest);
                            }).catch((err) => {
                                return Promise.reject(err);
                            });
                        }
                        
                        originalRequest._retry = true;
                        isRefreshing = true;

                        return new Promise(async (resolve, reject) => {
                            try {
                                const response = await customAxios.post(urlData['url'] + '/reToken');

                                let accesstk = response.data.response.resultMessage;

                                let session = sessionStorage.getItem(AUTH_SESSION_KEY);
                                // let session = localStorage.getItem(AUTH_SESSION_KEY);
                                session = JSON.parse(session);
                                let newJson = {};
                                newJson['accessToken'] = accesstk;

                                session['tokenInfo'] = newJson;
                                sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
                                localStorage.setItem('REFRESH_SHARED_CREDENTIALS', JSON.stringify(session));
                                localStorage.removeItem('REFRESH_SHARED_CREDENTIALS');
                                // localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));

                                customAxios.defaults.headers.common['accessToken'] = accesstk;
                                originalRequest.headers['accessToken'] = accesstk;
                                processQueue(null, accesstk);
                                resolve(customAxios(originalRequest));
                            } catch (err) {
                                processQueue(err, null);
                                reject(err);
                            } finally {
                                isRefreshing = false;
                            }
                        });
                    } else if (error.response.data.response.resultCode === '305') {
                        // 마지막 동작 후 2시간 지난경우
                        if (isFlag) {
                            isFlag = false;
                            dispatch(logoutUser());
                            localStorage.setItem('CREDENTIALS_FLUSH', '');
                            localStorage.removeItem('CREDENTIALS_FLUSH');
                            window.location.href = '/';
                            alert(error.response.data.response.resultMessage);
                        }
                    } else if (error.response.data.response.resultCode === '306') {
                        // accesstoken 불일치
                        if (isFlag) {
                            isFlag = false;
                            dispatch(logoutUser());
                            localStorage.setItem('CREDENTIALS_FLUSH', '');
                            localStorage.removeItem('CREDENTIALS_FLUSH');
                            window.location.href = '/';
                            alert(error.response.data.response.resultMessage);
                        }
                    } else {
                        return error;
                    }
                } else {
                    return error;
                }
            }
        );
    }, [])
    return children;
};

customAxios.interceptors.request.use(
    async(config) => {
        let tempUser = getUserFromSession();
        let accessToken = tempUser.tokenInfo['accessToken'];

        config.headers['accessToken'] = accessToken;

        if (config.method === 'post') {
            config.headers['Content-Type'] = `application/json`;
        } else if (config.method === 'put') {
            config.headers['Content-Type'] = `application/json`;
        } else if (config.method === 'delete') {
            config.headers['Content-Type'] = `application/json`;
        } else if (config.method === 'patch') {
            config.headers['Content-Type'] = `application/json`;
        }

        return config;
    },
    (error) => {
    }
);

const AtoNAPI = axios.create();

export { customAxios, APICore, setAuthorization, AtoNAPI, getUserFromSession, CustomAxiosResponseInterceptor};
