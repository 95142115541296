import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import urlData from '../atons/json/url.json';
import { AtoNAPI } from './api/apiCore';

const TOKEN =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI';

var mock = new MockAdapter(axios);

export function configureFakeBackend() {
    let users = [
        {
            id: 1,
            memberID: 'test',
            userPassword: 'test',
            firstName: 'Test',
            lastName: 'User',
            role: 'Admin',
            token: TOKEN,
        },
    ];

    mock.onPost('/login/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                const data = { memberID: params.memberID, userPassword: params.userPassword };

                AtoNAPI.post(urlData['url'] + '/login', JSON.stringify(data), {
                    headers: {
                        'Content-Type': `application/json`,
                    },
                })
                    .then((response) => {
                        if (response.data.response.resultCode !== undefined) {
                            if (response.data.response.resultCode === "300") {
                                resolve([300, {}]);
                                // 아이디없음
                            } else if (response.data.response.resultCode === "301") {
                                resolve([301, {}]);
                                // 메일인증 필요
                            } else if (response.data.response.resultCode === "302") {
                                resolve([302, {}]);
                                // 관리자 승인 필요
                            } else if (response.data.response.resultCode === "303") {
                                resolve([303, {}]);
                                // 회원이 탈퇴한 경우
                            } else if (response.data.response.resultCode === "304") {
                                resolve([304, {}]);
                                // 비밀번호가 틀린경우
                            }
                        } else {
                            // let tempAuthToken = response.headers.authtoken.replace('AuthToken(','');
                            // tempAuthToken = tempAuthToken.split(',');

                            let token = {
                                // authKey: tempAuthToken[0].replace('authKey=', ''),
                                accessToken: response.data.response.accessToken
                            };

                            //token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
                            
                            let user = response.data.response;
                            
                            let responseJson = {
                                userMrn: user.userMrn,
                                userId: user.userId,
                                userName: user.userName,
                                districtOfficeCode: user.districtOfficeCode,
                                mainImg: user.mainImg,
                                ownerAgency: user.ownerAgency,
                                ownerAgencyMrn: user.ownerAgencyMrn,
                                position: user.position,
                                tokenInfo: token,
                            };
                            resolve([200, responseJson]);
                        }
                    })
                    .catch((error) => {
                        resolve([401, { message: 'userId or password is incorrect' }]);
                    });
            }, 1000);
        });
    });

    // mock.onPost('/login/').reply(function (config) {
    //     return new Promise(function (resolve, reject) {
    //         setTimeout(function () {
    //             // get parameters from post request
    //             let params = JSON.parse(config.data);

    //             // find if any user matches login credentials
    //             let filteredUsers = users.filter((user) => {
    //                 return user.userId === params.userId && user.password === params.password;
    //             });

    //             if (filteredUsers.length) {
    //                 // if login details are valid return user details and fake jwt token
    //                 let user = filteredUsers[0];
    //                 resolve([200, user]);
    //             } else {
    //                 // else return error
    //                 resolve([401, { message: 'userId or password is incorrect' }]);
    //             }
    //         }, 1000);
    //     });
    // });

    mock.onPost('/register/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                // add new users
                // let [firstName, lastName] = params.fullname.split(' ');
                let newUser = {
                    districtOfficeCode: params.districtOfficeCode,
                    jobTitle: params.jobTitle,
                    position: params.position,
                    userId: params.userId,
                    passwd: params.passwd,
                    businessNumber: params.businessNumber,
                    businessAddress: params.businessAddress,
                    companyName: params.companyName, 
                    mainPhone: params.mainPhone,
                    faxNumber: params.faxNumber,
                    tel: params.tel,
                    userName: params.userName,
                    role: 'Admin',
                    // token: TOKEN,
                };
                // users.push({ newUser });

                AtoNAPI.post(urlData['url'] + '/mng/user', JSON.stringify(newUser), {
                    headers: {
                        'Content-Type': `application/json`,
                    },
                }).then( response => {
                    if (response !== undefined) {
                        if (response.data !== undefined) {
                            if (response.data.resultCode === 201) {
                                resolve([201, { message: 'Succeed  to sign up for member'}]);
                            } else {
                                resolve([200, { message: 'Failed to sign up for member'}]);
                            }
                        }
                    }
                }).catch(error => {
                    resolve([401, { message: 'Failed to sign up for member'}]);
                })

                resolve([200, newUser]);
            }, 1000);
        });
    });

    mock.onPost('/forget-password/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.userId === params.userId;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let responseJson = {
                        message: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve([200, responseJson]);
                } else {
                    // else return error
                    resolve([401, { message: 'Sorry, we could not find any registered user with entered userId' }]);
                }
            }, 1000);
        });
    });
}
