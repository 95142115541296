// @flow
import { AuthActionTypes } from './constants';

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (memberID: string, userPassword: string): AuthAction => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { memberID, userPassword },
});

export const logoutUser = (): AuthAction => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (districtOfficeCode: string, jobTitle: string, position: string
                        , userId: string, passwd: string, businessNumber: string, businessAddress: string
                        , companyName: string, mainPhone: string, faxNumber: string, tel: string, userName: string): AuthAction => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { districtOfficeCode, jobTitle, position, userId, passwd, businessNumber, businessAddress
            , companyName, mainPhone, faxNumber, tel, userName },
});

export const forgotPassword = (userId: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { userId },
});

export const forgotPasswordChange = (userId: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
    payload: { userId },
});

export const resetAuth = (): AuthAction => ({
    type: AuthActionTypes.RESET,
    payload: {},
});